<template>
  <div>
    <!--begin::Row-->
    <div class="row">
      <div class="col-xl-12">
        <!--begin::Nav Panel Widget 1-->
        <div class="card card-custom gutter-b">
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Nav Tabs-->
            <ul class="dashboard-tabs nav-pills row row-paddingless m-0 p-0 flex-sm-row" role="tablist">
              <!--begin::Item-->
              <li class="nav-item pill-1 nd-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0 align-items-center">
                <router-link v-slot="{ href, navigate }" custom to="/financial/snail">
                  <a
                    class="nav-link border py-10 d-flex flex-grow-1 rounded align-items-center justify-content-center"
                    data-toggle="pill"
                    :class="{ active: hasActiveChildren(href) }"
                    :href="href"
                    @click="navigate"
                  >
                    <span class="nav-icon w-auto">
                      <span class="svg-icon svg-icon-3x mr-2">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                        <inline-svg src="/media/svg/icons/Shopping/snail-money.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="font-weight-bold font-size-lg text-center">CARTEIRA SNAILCOIN</span>
                  </a>
                </router-link>
              </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="nav-item pill-2 d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0 align-items-center">
                <router-link v-slot="{ href, navigate }" custom to="/financial/real">
                  <a
                    class="nav-link border py-10 d-flex flex-grow-1 rounded align-items-center justify-content-center"
                    data-toggle="pill"
                    :class="{ active: hasActiveChildren(href) }"
                    :href="href"
                    @click="navigate"
                  >
                    <span class="nav-icon w-auto">
                      <span class="svg-icon svg-icon-3x mr-2">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                        <inline-svg src="/media/svg/icons/Shopping/Money.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </span>
                    <span class="font-weight-bold font-size-lg text-center">CARTEIRA REAIS</span>
                  </a>
                </router-link>
              </li>
            </ul>
            <!--end::Nav Tabs-->
            <!--begin::Nav Content-->

            <!--end::Nav Content-->
          </div>
          <!--end::Body-->
        </div>
        <!--begin::Nav Panel Widget 1-->
      </div>
    </div>
    <!--end::Row-->

    <transition name="fade-in-up">
      <!--name="custom-classes-transition" enter-active-class="animate__animated animate__fadeInUp" -->
      <router-view />
    </transition>
  </div>

  <!--end::Container-->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentStatus'])
  }
};
</script>
